import React, { useState } from 'react';
import './Home.css';
import  './VegetableCard.css';

interface Vegetable {
  VegetableID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
}

interface VegetableCardProps {
  vegetable: Vegetable;
  onAddToCart: (vegetable: Vegetable, isKg: boolean, priceInKg: number) => void; // Include priceInKg for cart
  onInputChange: (vegetableName: string, newValue: string) => void;
  inputValue: string;
  isSearchResult?: boolean;
}

const VegetableCard: React.FC<VegetableCardProps> = ({ vegetable, onAddToCart, onInputChange, inputValue , isSearchResult  }) => {
  const [isKg, setIsKg] = useState(vegetable.Unit === 'kg');
  const [isUnitsButtonActive, setIsUnitsButtonActive] = useState(!isKg);
  const [isKgButtonActive, setIsKgButtonActive] = useState(isKg);
  const cardClassName = isSearchResult ? 'veGisDivs searchResult' : 'veGisDivs';


  const toggleToKg = () => {
    setIsKg(true);
    setIsKgButtonActive(true);
    setIsUnitsButtonActive(false);
  };

  const toggleToUnits = () => {
    setIsKg(false);
    setIsKgButtonActive(false);
    setIsUnitsButtonActive(true);
  };

  const calculatePrice = () => {
    if (vegetable.Unit === 'bag') {
      const quantity = parseFloat(inputValue);
      const pricePerKg = vegetable.Price / vegetable.Quantity; // Price per kg based on the Quantity property
      const price = pricePerKg * quantity;
      return Number(price.toFixed(2));
    } else {
      const pricePerUnit = isKg ? vegetable.Price : vegetable.Price / 6; // Adjusted for units
      const quantity = parseFloat(inputValue);
      const price = pricePerUnit * quantity;
      return Number(price.toFixed(2));
    }
  };

  const incrementQuantity = () => {
    const newValue = vegetable.AllowsHalfKg && isKg
      ? (parseFloat(inputValue) + 0.5).toFixed(1)
      : (parseFloat(inputValue) + 1).toString();
    onInputChange(vegetable.Name, newValue);
  };

  const decrementQuantity = () => {
    const newValue = vegetable.AllowsHalfKg && isKg
      ? Math.max(0, parseFloat(inputValue) - 0.5).toFixed(1)
      : Math.max(0, parseFloat(inputValue) - 1).toString();
    onInputChange(vegetable.Name, newValue);
  };

  const handleAddToCart = () => {
    if (vegetable.Unit === 'bag') {
      const priceInKg = calculatePrice(); // Calculate price per kg for 'bag'
      onAddToCart(vegetable, true, priceInKg); // Always pass isKg as true to ensure kg price is used in the cart
    } else {
      onAddToCart(vegetable, isKg, calculatePrice()); // Pass isKg as is and calculate regular price
    }
  };

  return (
    <div className={cardClassName}>
      <img className="vegetablesImgs" src={vegetable.ImgUrl} alt={vegetable.Name} />
      <div className="h3Divs">
        <h3 className="vegetableName">{vegetable.Name}</h3>
      </div>
      <div className="pDiv">
        {vegetable.Unit === 'bag' ? (
          <p>מארז: ₪ {vegetable.Price.toFixed(2)}</p>
        ) : (
          <p>ק"ג: ₪ {vegetable.Price.toFixed(2)}</p>
        )}
      </div>

      <div className="buttonsDiv">
        <div className='addToCartDiv'>
        <button className="addToCartButton" onClick={handleAddToCart}> הוסף לסל </button>
        </div>
        <div className="AddRemoveDiv">
          <button className="quantity-btn" onClick={decrementQuantity}>-</button>
          <span className="spanNum">{inputValue}</span>
          <button className="quantity-btn" onClick={incrementQuantity}>+</button>
        </div>
        {vegetable.canToggleUnits && (
          <div className="toggleButtons">
            <button className={isUnitsButtonActive ? "toggle-button active" : "toggle-button"} onClick={toggleToUnits}>'יח</button>
            <button className={isKgButtonActive ? "toggle-button-second active" : "toggle-button-second"} onClick={toggleToKg}>ק"ג</button>
          </div>
        )}
      </div>
    </div>
  );
};


export default VegetableCard;

