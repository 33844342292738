import React, { useState } from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const handleSectionClick = (section: string) => {
    setActiveSection(section === activeSection ? null : section);
  };

  return (
    <footer className="footer">
      <div className="footer-links">
        <button onClick={() => handleSectionClick('accessibilityStatement')}>
        הצהרת נגישות 
        </button>
        <button onClick={() => handleSectionClick('privacyPolicy')}>
        מדיניות פרטיות
        </button>
        <button onClick={() => handleSectionClick('termsOfService')}>
        תנאי שימוש והגבלות
        </button>
      </div>
      {activeSection === 'accessibilityStatement' && (
        <div className="footer-content">
          <h2>הצהרת נגישות</h2>
          <p>
          תאריך תוקף: [27.8.24]

          אנו מחויבים להבטיח נגישות דיגיטלית לאנשים עם מוגבלות. אנו שואפים לשפר את חווית המשתמש עבור כולם וליישם את תקני הנגישות הרלוונטיים.

אמצעים לתמיכה בנגישות
אנו נוקטים באמצעים הבאים כדי להבטיח את הנגישות של עסיס פירות וירקות אורגניים:

מאמצים מתמשכים: בדיקה ועדכון קבוע של האתר שלנו כך שיעמוד בתקני WCAG 2.1 AA.
משוב משתמשים: עידוד משתמשים לספק משוב על נגישות וטיפול מיידי בכל בעיה.
סטטוס התאמה
הנחיות הנגישות לתוכן אינטרנט (WCAG) מגדירים דרישות למעצבים ולמפתחים לשיפור הנגישות לאנשים עם מוגבלות. Assis תואם חלקית ל-WCAG 2.1 רמה AA. תואם חלקית פירושו שחלקים מסוימים של התוכן אינם תואמים באופן מלא לתקן הנגישות.

תכונות נגישות
ניווט במקלדת: האתר שלנו ניתן לניווט מלא באמצעות מקלדת.
טקסט חלופי: תמונות כוללות טקסט חלופי תיאורי עבור קוראי מסך.
ניגודיות טקסט: אנו מבטיחים ניגודיות צבע מספקת בין טקסט לרקע.
טקסט הניתן לשינוי גודל: משתמשים יכולים לשנות את גודל הטקסט ללא אובדן תוכן או פונקציונליות.
מגבלות וחלופות
למרות מאמצינו, ייתכן שחלק מהאזורים באתר עדיין לא יהיו נגישים במלואם. להלן תחומים שבהם נוכל לשפר:

תוכן של צד שלישי: ייתכן שחלק מהתוכן של צד שלישי לא יהיה נגיש במלואו. אנו עובדים עם השותפים שלנו כדי לטפל בבעיות אלו.
מָשׁוֹב
נשמח לקבל את המשוב שלך על הנגישות של Assis. אנא הודע לנו אם אתה נתקל במחסומי נגישות:

אימייל: [אימייל שלך]
טלפון: [מספר הטלפון שלך]
אנו שואפים להגיב למשוב על נגישות תוך [ציין מסגרת זמן], ואנו מתייחסים למשוב משתמשים ברצינות כחלק מהמחויבות שלנו לנגישות.

תאימות עם דפדפנים וטכנולוגיה מסייעת
האתר שלנו נועד להיות תואם לדפדפנים ולטכנולוגיות המסייעות הבאים:

דפדפנים: גרסאות אחרונות של Chrome, Firefox, Safari ו-Edge.
טכנולוגיות מסייעות: קוראי מסך כגון JAWS ו-NVDA.
מפרט טכני
הנגישות של Assis מסתמכת על הטכנולוגיות הבאות:

HTML
CSS
JavaScript
טכנולוגיות אלו מסתמכות על התאמה לתקני הנגישות המשמשים.


 אנו בודקים את באופן תקופתי את הנגישות של האתר שלנו באמצעות הערכה עצמית וביקורות תקופתיות של צד שלישי .

תלונות רשמיות
אם אינך מרוצה מהתגובה שלנו למשוב שלך, תוכל לפנות אלינו ישירות כדי להסלים את הבעיה. אנו מחויבים לפתור כל בעיה של נגישות.

הצהרה זו נוצרה בתאריך 27.8.24 והיא תיבדק באופן קבוע כדי להבטיח את המחויבות המתמשכת שלנו לנגישות.
          </p>
          <p>
          אם אתה נתקל בקושי כלשהו בגישה לחלק כלשהו באתר זה, אנא אל תהסס לעשות זאת
            התקשר אלינו למספר 0542567775 או שלח לנו דוא"ל לכתובת michaelarbiv@gmail.com ואנו נעבוד איתך כדי לספק
            המידע שאתה צריך באמצעות שיטת תקשורת חלופית.

          </p>
        </div>
      )}
      {activeSection === 'privacyPolicy' && (
        <div className="footer-content">
          <h2>מדיניות פרטיות
          </h2>
          <p>
          ב-Assis, אנו מחויבים להגן על פרטיותך. מדיניות פרטיות זו מתארת ​​כיצד אנו אוספים, משתמשים ומגנים על המידע האישי שלך כאשר אתה מבקר באתר האינטרנט שלנו, https://assisv.ddnsking.com/.

1. מידע שאנו אוספים
מידע אישי: אנו אוספים מידע אישי כגון שמך, כתובת הדוא"ל, מספר הטלפון ופרטי התשלום שלך בעת יצירת חשבון, ביצוע הזמנה או יצירת קשר.
נתוני שימוש: אנו עשויים לאסוף מידע על האופן שבו אתה מקיים אינטראקציה עם האתר שלנו, כולל כתובות IP, סוג דפדפן ודפים שבהם ביקרת.
2. כיצד אנו משתמשים במידע שלך
כדי לספק שירותים: אנו משתמשים במידע האישי שלך כדי לעבד הזמנות, לספק תמיכת לקוחות ולנהל את חשבונך.
כדי לשפר את האתר שלנו: אנו מנתחים נתוני שימוש כדי לשפר את הפונקציונליות וחווית המשתמש של האתר שלנו.
שיווק: בהסכמתך, אנו עשויים לשלוח לך דוא"ל פרסומי על המוצרים והשירותים שלנו. אתה יכול לבטל את הסכמתך בכל עת.
3. שיתוף המידע שלך
ספקי שירות של צד שלישי: אנו עשויים לחלוק את המידע שלך עם ספקי צד שלישי מהימנים כדי להקל על השירותים שלנו, כגון עיבוד תשלומים ומימוש הזמנות.
דרישות משפטיות: אנו עשויים לחשוף את המידע שלך אם נדרש על פי חוק או כדי להגן על זכויותינו.
4. אבטחה
אנו מיישמים אמצעי אבטחה סבירים כדי להגן על המידע האישי שלך מפני גישה בלתי מורשית, שינוי או הרס. עם זאת, אף שיטה לשידור דרך האינטרנט אינה מאובטחת לחלוטין.

5. הזכויות שלך
יש לך את הזכות לגשת, לתקן או למחוק את המידע האישי שלך. אתה יכול גם להתנגד או להגביל את העיבוד שלנו של הנתונים שלך. כדי לממש זכויות אלה, אנא צור איתנו קשר בכתובת [הכנס פרטי קשר].

6. עוגיות
אנו משתמשים בקובצי Cookie כדי לשפר את חווית הגלישה שלך, לנתח את התנועה באתר ולהתאים אישית את התוכן. אתה יכול לשלוט בהעדפות קובצי Cookie דרך הגדרות הדפדפן שלך.

7. שינויים במדיניות זו
אנו עשויים לעדכן את מדיניות הפרטיות הזו מעת לעת. כל שינוי יפורסמו בדף זה עם תאריך התוקף המעודכן.

8. צור קשר
אם יש לך שאלות או חששות לגבי מדיניות פרטיות זו, אנא צור איתנו קשר בכתובת:

דוא"ל: [michaelarbiv@gmail.com]
טלפון: [0542567775]

          </p>
      
        </div>
      )}
      {activeSection === 'termsOfService' && (
        <div className="footer-content">
          <h2>תנאי שימוש והגבלות</h2>
          <p>
          תאריך תוקף: [27.8.24]

ברוכים הבאים  לעסיס פירות וירקות אורגניים . על ידי גישה או שימוש באתר שלנו, https://assisv.ddnsking.com/, אתה מסכים לציית ולהיות מחויב לתנאים ולהגבלות הבאים.

1. הסכמה לתנאים
על ידי שימוש בשירותים שלנו, אתה מסכים לתנאי שירות אלה ולכל הנחיות או כללים נוספים שעשויים להתפרסם.

2. רישום חשבון
עליך ליצור חשבון כדי לגשת לתכונות מסוימות. אתה אחראי לשמירה על סודיות פרטי החשבון שלך.

3. שימוש באתר
אתה מסכים להשתמש באתר שלנו רק למטרות חוקיות. אין להשתמש באתר שלנו בכל דרך העלולה לפגוע, להשבית או לפגוע באתר או להפריע לשימוש של אחרים.

4. הזמנות ותשלומים
כל ההזמנות כפופות לאישור לעסיס פירות וירקות אורגניים . אנו שומרים לעצמנו את הזכות לסרב או לבטל כל הזמנה. יש לבצע את התשלום במלואו לפני המסירה.

5. קניין רוחני
כל התוכן באתר האינטרנט שלנו, לרבות טקסט, גרפיקה, סמלי לוגו ותמונות, הוא רכושם של לעסיס פירות וירקות אורגניים או של ספקי התוכן שלנו ומוגן על ידי חוקי קניין רוחני.

6. הגבלת אחריות
לעסיס פירות וירקות אורגניים אינה אחראית לכל נזק עקיף, מקרי או תוצאתי הנובע מהשימוש באתר או בשירותים שלנו.

7. חוק חל
תנאים אלה נשלטים על ידי חוקים של [המדינה/מדינתך] ומתפרשים על פי החוקים של [המדינה/מדינתך], ללא התחשבות בהוראות החוק שלהן.

8. שינויים בתנאים
אנו עשויים לעדכן את התנאים וההגבלות הללו מעת לעת. כל שינוי יתפרסם בעמוד זה, והמשך השימוש שלך באתר לאחר שינויים כאמור מהווה הסכמה שלך לתנאים החדשים.

9. צור קשר
אם יש לך שאלות כלשהן לגבי תנאים והגבלות אלה, אנא צור איתנו קשר בכתובת:

דוא"ל: [michaelarbiv@gmail.com]
טלפון: [0542567775]
          </p>
     
        </div>
      )}
    </footer>
  );
};

export default Footer;
