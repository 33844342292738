import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import './Welcome.css';

const Welcome: React.FC = () => {
  const { currentUser, fetchCurrentUser } = useAuth();
  const [newCity, setNewCity] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newHouseNumber, setNewHouseNumber] = useState('');

  useEffect(() => {
    if (!currentUser) {
      fetchCurrentUser().catch(error => console.error('Error fetching current user:', error));
    } else {
      setNewCity(currentUser.city || '');
      setNewStreet(currentUser.street || '');
      setNewHouseNumber(currentUser.houseNumber || '');
    }
  }, [currentUser, fetchCurrentUser]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Implement your update logic here
      console.log('Address updated:', newCity, newStreet, newHouseNumber);
      // Optionally, call an API to update user's address
      // await updateUserAddress(currentUser.id, newCity, newStreet, newHouseNumber);
      alert('Address updated successfully');
    } catch (error) {
      console.error('Error updating address:', error);
      alert('Failed to update address. Please try again.');
    }
  };

  if (!currentUser) {
    return <div>Loading...</div>; // Optionally show a loading state while fetching user
  }

  return (
    <div className="welcome-container">
      <h1>Welcome, {currentUser.name}</h1>
      <p>Email: {currentUser.email}</p>
      <div className="address-update">
        <h2>Update Address</h2>
        <form onSubmit={handleSubmit} className="address-form">
          <div className="form-group">
            <label>City:</label>
            <input
              type="text"
              value={newCity}
              onChange={(e) => setNewCity(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Street:</label>
            <input
              type="text"
              value={newStreet}
              onChange={(e) => setNewStreet(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>House Number:</label>
            <input
              type="text"
              value={newHouseNumber}
              onChange={(e) => setNewHouseNumber(e.target.value)}
            />
          </div>
          <button type="submit" className="update-button">Update Address</button>
        </form>
      </div>
    </div>
  );
};

export default Welcome;