import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useAuth } from './AuthProvider';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface Vegetable {
  VegetableID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
}

interface CartContextType {
  cartQuantities: { [name: string]: { quantity: number, isKg: boolean, price: number } };
  setCartQuantities: React.Dispatch<React.SetStateAction<{ [name: string]: { quantity: number, isKg: boolean, price: number } }>>;
  inputValues: { [name: string]: string };
  setInputValues: React.Dispatch<React.SetStateAction<{ [name: string]: string }>>;
  handleAddToCart: (vegetable: Vegetable, isKg: boolean) => Promise<void>;
}

interface CartProviderProps {
  children: ReactNode;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const { currentUser } = useAuth();
  const [cartQuantities, setCartQuantities] = useState<{ [name: string]: { quantity: number, isKg: boolean, price: number } }>({});
  const [inputValues, setInputValues] = useState<{ [name: string]: string }>({});

  const handleAddToCart = async (vegetable: Vegetable, isKg: boolean) => {
    const inputValue = inputValues[vegetable.Name];
    const quantity = parseFloat(inputValue);

    if (!isNaN(quantity) && quantity > 0) {
      try {
        const payload = {
          Name: vegetable.Name,
          VegetableID: vegetable.VegetableID,
          Quantity: quantity,
          ImgUrl: vegetable.ImgUrl,
          Price: vegetable.Price,
          UserID: currentUser?.id ? Number(currentUser.id) : undefined,
          isKg: isKg,
        };

        const response = await fetch(`${API_BASE_URL}/cart`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Failed to add item to cart');
        }

        setCartQuantities((prevQuantities) => ({
          ...prevQuantities,
          [vegetable.Name]: {
            quantity: (prevQuantities[vegetable.Name]?.quantity || 0) + quantity,
            isKg,
            price: vegetable.Price,
          },
        }));

        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [vegetable.Name]: '0',
        }));
      } catch (error) {
        console.error('Error adding item to cart:', error);
      }
    }
  };

  return (
    <CartContext.Provider value={{ cartQuantities, setCartQuantities, inputValues, setInputValues, handleAddToCart }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use CartContext
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
