import React, { useState } from 'react';
import { useAuth } from './AuthProvider';
import './Login.css';
import { GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';

interface LoginProps {
  onClose: () => void;
  onRegister: () => void;
}

const Login: React.FC<LoginProps> = ({ onClose, onRegister }) => {
  const { fetchCurrentUser, loginWithGoogle } = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleGoogleSignIn = async (credentialResponse: any) => {
    try {
      const googleToken = credentialResponse.credential;
      const decodedToken = jwtDecode(googleToken);
      console.log('Decoded token:', decodedToken);
      
      await loginWithGoogle(googleToken);
      console.log('Logged in successfully with Google');
      setSuccess('Logged in successfully!');

      await fetchCurrentUser(); // Fetch the current user after successful login

      onClose(); // Close the login form after successful login
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
      setError('אין לך חשבון בבקשה עבור לעמוד ההרשמה.');
    }
  };

  return (
    <div className="login-container">
      <h5>Sign in with Google</h5>
      <div className="form-group">
        <GoogleLogin
          onSuccess={handleGoogleSignIn}
          onError={() => setError('אין לך חשבון בבקשה עבור לעמוד ההרשמה.')}
        />
      </div>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
    </div>
  );
};

export default Login;
