import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {jwtDecode} from 'jwt-decode';

interface User {
  id: number;
  name: string;
  email?: string;
  role?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
  phone?: string;
}

interface AuthProviderProps {
  children: ReactNode;
}

export interface AuthContextType {
  currentUser: User | null;
  fetchCurrentUser: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  register: (name: string, email: string, password: string, city: string, street: string, houseNumber: string, phone: string) => Promise<void>;
  logout: () => void;
  isManager: () => boolean;
  loginWithGoogle: (googleToken: string) => Promise<void>;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      console.log('Restored user from localStorage:', parsedUser);
      setCurrentUser(parsedUser);
    }

    const interval = setInterval(() => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const decodedToken: any = jwtDecode(token);
          if (decodedToken.exp * 1000 < Date.now()) {
            logout(); // Logout user if token is expired
          }
        } catch (error) {
          console.error('Invalid token format', error);
          logout(); // Logout user if token is invalid
        }
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  const login = async (email: string, password: string) => {
    try {
      let userData: User | null = null;

      // Check for admin credentials
      if (email === 'admin@example.com' && password === '123456789') {
        userData = { id: 1, name: 'Admin', email: 'admin@example.com', role: 'admin' };
        console.log('Admin login successful:', userData);
      } else {
        // If not admin, proceed with regular login
        const response = await fetch(`${API_BASE_URL}/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password })
        });

        if (!response.ok) {
          throw new Error('Invalid email or password');
        }

        const data = await response.json();
        const { token, user } = data;

        localStorage.setItem('authToken', token);
        userData = {
          id: user.UserID,
          name: user.Name,
          email: user.Email,
          city: user.City,
          street: user.Street,
          houseNumber: user.HouseNumber,
          phone: user.Phone,
        };
      }

      // Save user data to localStorage and state
      localStorage.setItem('currentUser', JSON.stringify(userData));
      setCurrentUser(userData);
      console.log('Login successful:', userData);
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const loginWithGoogle = async (googleToken: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/google`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: googleToken })
      });

      if (!response.ok) {
        throw new Error('Google login failed');
      }

      const data = await response.json();
      const { token, user } = data;

      localStorage.setItem('authToken', token);
      const structuredUser: User = {
        id: user.UserID,
        name: user.Name,
        email: user.Email,
        city: user.City,
        street: user.Street,
        houseNumber: user.HouseNumber,
        phone: user.Phone,
      };
      localStorage.setItem('currentUser', JSON.stringify(structuredUser));
      setCurrentUser(structuredUser);
      console.log('Google login successful:', structuredUser);
    } catch (error) {
      console.error('Error during Google login:', error);
      throw error;
    }
  };

  const fetchCurrentUser = async (): Promise<void> => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) throw new Error('No token found');

      const response = await fetch(`${API_BASE_URL}/user/current-user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch current user');
      }

      const userData = await response.json();
      const structuredUser: User = {
        id: userData.UserID,
        name: userData.Name,
        email: userData.Email,
        city: userData.City,
        street: userData.Street,
        houseNumber: userData.HouseNumber,
        phone: userData.Phone,
      };
      localStorage.setItem('currentUser', JSON.stringify(structuredUser));
      setCurrentUser(structuredUser);
      console.log('Fetched current user:', structuredUser);
    } catch (error) {
      console.error('Error fetching current user:', error);
      logout();
    }
  };

  const register = async (name: string, email: string, password: string, city: string, street: string, houseNumber: string, phone: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, password, city, street, houseNumber, phone })
      });

      if (!response.ok) {
        throw new Error('Registration failed');
      }

      const user = await response.json();
      const structuredUser: User = {
        id: user.UserID,
        name: user.Name,
        email: user.Email,
        city: user.City,
        street: user.Street,
        houseNumber: user.HouseNumber,
        phone: user.Phone,
      };
      localStorage.setItem('currentUser', JSON.stringify(structuredUser));
      setCurrentUser(structuredUser);
      console.log('Registration successful:', structuredUser);
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem('currentUser'); // Clear local storage
      localStorage.removeItem('authToken');
      setCurrentUser(null); // Reset your state management
      console.log('Successfully logged out');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const isManager = () => {
    return currentUser?.role === 'admin';
  };

  const value: AuthContextType = {
    currentUser,
    fetchCurrentUser,
    login,
    register,
    logout,
    isManager,
    loginWithGoogle,
    setCurrentUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
