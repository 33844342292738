import React, { useState, useEffect } from 'react';
import './VegetableManager.css';

interface Vegetable {
  VegetableID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  canToggleUnits: boolean;
  isOutOfStock: boolean;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VegetableManager: React.FC = () => {
  const [vegetables, setVegetables] = useState<Vegetable[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchVegetables();
  }, []);

  // Fetch vegetables from the backend
  const fetchVegetables = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables`);
      if (!response.ok) {
        throw new Error('Failed to fetch vegetables');
      }
      const data = await response.json();
      setVegetables(data);
    } catch (error) {
      setErrorMessage('Error fetching vegetables');
      console.error('Error fetching vegetables:', error);
    }
  };

  // Mark vegetable as out of stock
  const markAsOutOfStock = async (vegetableId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables/${vegetableId}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isOutOfStock: true }),
      });
      if (!response.ok) {
        throw new Error('Failed to mark as Out of Stock');
      }
      setVegetables(prevVegetables =>
        prevVegetables.map(veg =>
          veg.VegetableID === vegetableId ? { ...veg, isOutOfStock: true } : veg
        )
      );
      setSuccessMessage('Vegetable marked as Out of Stock');
    } catch (error) {
      setErrorMessage('Error marking as Out of Stock');
      console.error('Error marking as Out of Stock:', error);
    }
  };

  // Mark vegetable as in stock
  const markAsInStock = async (vegetableId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables/${vegetableId}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isOutOfStock: false }),
      });
      if (!response.ok) {
        throw new Error('Failed to mark as In Stock');
      }
      setVegetables(prevVegetables =>
        prevVegetables.map(veg =>
          veg.VegetableID === vegetableId ? { ...veg, isOutOfStock: false } : veg
        )
      );
      setSuccessMessage('Vegetable marked as In Stock');
    } catch (error) {
      setErrorMessage('Error marking as In Stock');
      console.error('Error marking as In Stock:', error);
    }
  };

  // Delete vegetable
  const handleDelete = async (vegetableId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables/${vegetableId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete vegetable');
      }
      setVegetables(prevVegetables =>
        prevVegetables.filter(veg => veg.VegetableID !== vegetableId)
      );
      setSuccessMessage('Vegetable deleted successfully');
    } catch (error) {
      setErrorMessage('Error deleting vegetable');
      console.error('Error deleting vegetable:', error);
    }
  };

  return (
    <div className="vegetable-manager">
      <h2>Vegetable Manager</h2>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      <ul className="vegetable-list">
        {vegetables.map((vegetable) => (
          <li key={vegetable.VegetableID} className="vegetable-item">
            <p>{vegetable.Name} - ₪{vegetable.Price.toFixed(2)} - {vegetable.Quantity} - {vegetable.Unit}</p>

            {vegetable.isOutOfStock && <span className="out-of-stock">Out of Stock</span>}

            <button onClick={() => markAsInStock(vegetable.VegetableID)} disabled={!vegetable.isOutOfStock}>
              In Stock
            </button>

            <button onClick={() => markAsOutOfStock(vegetable.VegetableID)} disabled={vegetable.isOutOfStock}>
              Out of Stock
            </button>

            <button onClick={() => handleDelete(vegetable.VegetableID)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VegetableManager;
